import React, { useEffect, useState } from "react";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";
import AirflowIcon from "../assets/icons/airflow.svg";
import AsanaIcon from "../assets/icons/asana.svg";
import AwsIcon from "../assets/icons/aws.svg";
import AzureIcon from "../assets/icons/azure.svg";
import BashIcon from "../assets/icons/bash.svg";
import BitbucketIcon from "../assets/icons/bitbucket.svg";
import CloudBuildIcon from "../assets/icons/cloud-build.svg";
import CssIcon from "../assets/icons/css.svg";
import DoIcon from "../assets/icons/do.svg";
import DockerIcon from "../assets/icons/docker.svg";
import FigmaIcon from "../assets/icons/figma.svg";
import FirebaseIcon from "../assets/icons/firebase.svg";
import FlutterIcon from "../assets/icons/flutter.svg";
import GitHubIcon from "../assets/icons/github.svg";
import GitHubGrayIcon from "../assets/icons/github-2.svg";
import GolangIcon from "../assets/icons/golang.svg";
import GoogleCloudIcon from "../assets/icons/google-cloud.svg";
import GrpcIcon from "../assets/icons/grpc.svg";
import Hr from "../assets/icons/hr.svg";
import HtmlIcon from "../assets/icons/html.svg";
import JavaIcon from "../assets/icons/java.svg";
import JavaScriptIcon from "../assets/icons/javascript.svg";
import JiraIcon from "../assets/icons/jira.svg";
import KubernetesIcon from "../assets/icons/kubernetes.svg";
import Linkedin from "../assets/icons/linkedin.svg";
import MediumIcon from "../assets/icons/medium.svg";
import NestIcon from "../assets/icons/nestjs.svg";
import NextIcon from "../assets/icons/nextjs.svg";
import NodeIcon from "../assets/icons/nodejs.svg";
import PythonIcon from "../assets/icons/python.svg";
import LaravelIcon from "../assets/icons/laravel.svg";
import ReactIcon from "../assets/icons/reactjs.svg";
import TypeScriptIcon from "../assets/icons/typescript.svg";
import VueIcon from "../assets/icons/vuejs.svg";
import Wave from "../assets/images/wave.png";
import Card from "../components/card";
import CollapseBox from "../components/collapseBox";
import Header from "../components/header";
import Hero from "../components/hero";
import SkillBox from "../components/skillBox";

const Container = styled.div`
  padding: 0px 30px;
  max-width: 1200px;
  margin: 0px auto;
  min-height: ${({ height }) => height}px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

const ExperienceContainer = styled.div`
  margin-bottom: 20px;
`;

const ActivityContainer = styled.div`
  margin-bottom: 20px;
`;

const HrContainer = styled.div`
  width: 100%;
  overflow: hidden;
  margin-bottom: 50px;
`;

const BottomContainer = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: 50px;
  display: block;
  display: flex;
  align-items: flex-end;
  position: relative;
  height: 300px;

  @media (max-width: 768px) {
    height: 200px;
  }
`;

const LinkContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LinkText = styled.a`
  cursor: pointer;
  text-decoration: unset;
  font-weight: 700;
  font-size: 12px;
  color: #333333;
`;

const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const SocialIcon = styled.div`
  margin: 0px 12px;
`;

const WaveImage = styled.img`
  position: relative;
  height: 300px;
  width: 100%;
  object-fit: cover;
`;

const EndSection = styled.div`
  height: 100px;

  @media (max-width: 768px) {
    height: 100px;
  }
`;

const experiences = [
  {
    start_date: "Mar 2024",
    end_date: "Present",
    title: "Purple Ventures (Robinhood)",
    sub_title: "Backend Engineer",
  },
  {
    start_date: "Sep 2021",
    end_date: "Feb 2024",
    title: "SCB 10X",
    sub_title: "Software Engineer",
  },
  {
    start_date: "Aug 2020",
    end_date: "Sep 2021",
    title: "The Existing",
    sub_title: "Full Stack Developer",
  },
  {
    start_date: "Apr 2019",
    end_date: "Jul 2020",
    title: "The Sixtynine Communication",
    sub_title: "Full Stack Developer",
  },
  {
    start_date: "Jul 2018",
    end_date: "Mar 2019",
    title: "KISRA",
    sub_title: "Full Stack Developer",
  },
  {
    start_date: "Feb 2018",
    end_date: "Jun 2018",
    title: "Freelancer",
    sub_title: "Developer",
  },
  {
    start_date: "Jun 2017",
    end_date: "Jan 2018",
    title: "Facgure",
    sub_title: "Full Stack Developer (Internship)",
  },
];

const education = [
  {
    start_date: "Aug 2014",
    end_date: "May 2018",
    title: "Kasetsart University",
    sub_title: "Bachelor of Science - Computer Science",
  },
];

const activities = [
  {
    date: "Oct 2020",
    title: "LINE HACK 2020 Finalist",
    sub_title: "Backend Developer @ Kafaak",
    details: [
      "Designed database schema and API specifications.",
      "Developed REST API using Node.js with LINE API.",
      "Set up cloud infrastructure and automated deployment pipelines.",
    ],
  },
];

const skills = [
  {
    title: "Frontend Development",
    skills: [
      {
        icon: <ReactIcon height="14" />,
        title: "React.js",
      },
      {
        icon: <ReactIcon height="14" />,
        title: "React Native",
      },
      {
        icon: <VueIcon height="14" />,
        title: "Vue.js",
      },
      {
        icon: <NextIcon height="12" />,
        title: "Next.js",
      },
      {
        icon: <FlutterIcon height="12" />,
        title: "Flutter",
      },
    ],
  },
  {
    title: "Backend Development",
    skills: [
      {
        icon: <NodeIcon height="14" />,
        title: "Node.js",
      },
      {
        icon: <NestIcon height="14" />,
        title: "Nest.js",
      },
      {
        icon: <LaravelIcon height="14" />,
        title: "Laravel",
      },
      {
        icon: <GrpcIcon height="14" />,
        title: "gRPC",
      },
    ],
  },
  {
    title: "Programming Language",
    skills: [
      {
        icon: <PythonIcon height="14" />,
        title: "Python",
      },
      {
        icon: <JavaIcon height="14" />,
        title: "Java",
      },
      {
        icon: <BashIcon height="14" />,
        title: "Shell Script",
      },
      {
        icon: <HtmlIcon height="14" />,
        title: "HTML",
      },
      {
        icon: <CssIcon height="14" />,
        title: "CSS",
      },
      {
        icon: <JavaScriptIcon height="14" />,
        title: "JavaScript",
      },
      {
        icon: <TypeScriptIcon height="14" />,
        title: "TypeScript",
      },
      {
        icon: <GolangIcon height="14" />,
        title: "Golang",
      },
    ],
  },
  {
    title: "Deployment",
    skills: [
      {
        icon: <DockerIcon height="12" />,
        title: "Docker",
      },
      {
        icon: <DockerIcon height="12" />,
        title: "Docker Compose",
      },
      {
        icon: <KubernetesIcon height="16" width="18" />,
        title: "Kubernetes",
      },
    ],
  },
  {
    title: "CI/CD",
    skills: [
      {
        icon: <CloudBuildIcon height="14" />,
        title: "Cloud Build",
      },
      {
        icon: <GitHubIcon height="12" />,
        title: "GitHub Actions",
      },
      {
        icon: <BitbucketIcon height="12" />,
        title: "Bitbucket Pipelines",
      },
    ],
  },
  {
    title: "Cloud",
    skills: [
      {
        icon: <GoogleCloudIcon height="12" />,
        title: "Google Cloud",
      },
      {
        icon: <AwsIcon height="12" />,
        title: "AWS",
      },
      {
        icon: <AzureIcon height="12" />,
        title: "Bitbucket Pipelines",
      },
      {
        icon: <DoIcon height="12" />,
        title: "Digital Ocean",
      },
      {
        icon: <FirebaseIcon height="16" />,
        title: "Firebase",
      },
    ],
  },
  {
    title: "Tools",
    skills: [
      {
        icon: <FigmaIcon height="12" />,
        title: "Figma",
      },
      {
        icon: <AsanaIcon height="12" />,
        title: "Asana",
      },
      {
        icon: <JiraIcon height="12" />,
        title: "Jira",
      },
      {
        icon: <GitHubIcon height="12" />,
        title: "GitHub",
      },
      {
        icon: <BitbucketIcon height="12" />,
        title: "Bitbucket",
      },
      {
        icon: <AirflowIcon height="14" />,
        title: "Airflow",
      },
    ],
  },
];

const IndexPage = () => {
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenHeight(window.innerHeight);
    });
    setScreenHeight(window.innerHeight);
  }, []);

  return (
    <>
      <Global
        styles={css`
          html,
          body {
            font-family: Nunito, -apple-system, sans-serif, serif;
            margin: 0;
            -webkit-text-size-adjust: 100%;
          }
        `}
      />
      <Container height={screenHeight}>
        <title>Boomauakim</title>
        <Header />
        <Hero />
        <Title>A brief history</Title>
        {experiences.map((data, key) => (
          <ExperienceContainer key={key}>
            <CollapseBox data={data} />
          </ExperienceContainer>
        ))}
        <HrContainer>
          <Hr width="1200px" />
        </HrContainer>
        {education.map((data, key) => (
          <ExperienceContainer key={key}>
            <CollapseBox
              key={key}
              data={data}
              dateTagColor="#397d54"
              dateTagBackground="#edf7f1"
            />
          </ExperienceContainer>
        ))}
        <EndSection />
        <Title>Activities</Title>
        {activities.map((data, key) => (
          <ActivityContainer key={key}>
            <Card data={data} />
          </ActivityContainer>
        ))}
        <EndSection />
        <Title>Skills</Title>
        {skills.map((data, key) => (
          <div key={key}>
            <SkillBox data={data} />
          </div>
        ))}
      </Container>
      <BottomContainer>
        <WaveImage src={Wave} />
        <LinkContainer>
          <SocialContainer>
            <SocialIcon>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://medium.com/@boomauakim"
              >
                <MediumIcon height="18" />
              </a>
            </SocialIcon>
            <SocialIcon>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/boomauakim"
              >
                <GitHubGrayIcon height="18" />
              </a>
            </SocialIcon>
            <SocialIcon>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://th.linkedin.com/in/kamonwatch"
              >
                <Linkedin height="18" />
              </a>
            </SocialIcon>
          </SocialContainer>
          <LinkText href="mailto:hello@boomauakim.com">
            hello@boomauakim.com
          </LinkText>
        </LinkContainer>
      </BottomContainer>
    </>
  );
};

export default IndexPage;
